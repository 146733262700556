.search-container {
  // THIS IS THE GRID CONTAINER FOR ALL CARDS
  display: grid;
  border: none;
  column-gap: 1rem;
  row-gap: 1rem;
  padding-bottom: 5rem;
  @include respond-to('tablet') {
    grid-template-columns: 1fr 1fr;
  }
  @include respond-to('desktop') {
    grid-template-columns: 1fr 1fr;
  }
  a {
    color: $primary;
    text-decoration: none;
  }
  a:hover {
    color: $info;
    text-decoration: underline;
    border: solid 1px $info;
  }
  .card {
    max-height: 70vh;
    background-color: $body;
  }
}

.genre-container {
  margin-top: 8rem;
  display: grid;
  grid-template-columns: 1fr;
  @include respond-to('desktop') {
    margin-top: 0;
  }
}

.genre-img-wrapper {
  position: relative;
  /* Ensure the Card Body takes the full height of the image */
  display: flex;
  align-items: stretch;
}

.genre-tombstone {
  visibility: hidden;
  @include respond-to('desktop') {
    position: absolute;
    visibility: visible;
    background-color: $secondary; /* adjust this as needed */
    top: 70%; /* Center vertically */
    left: 30%; /* Center horizontally */
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1rem;
    opacity: .8;
    border-radius: $card-radius;
    color: $primary;
    /* Make sure the text is on top of the image */
    z-index: 2;
    /* Flexbox for centering content */
    display: flex;
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
  }
}

.genres-heading {
  color: $primary;
  text-align: center;
  background: rgb(9,26,54);
  background: linear-gradient(180deg, rgba(9,26,54,0) 19%, rgba(242,242,242,1) 37%);
  padding: 1rem 0 0 0;
  margin: 0;
}

.genre-card .card-img-top {
  width: 100%;
  height: auto; /* Adjust height as needed */
}

.genre-carousel {
  display: none;
  @include respond-to('desktop') {
    display: block;
  }
}


.genre-card .card-body {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 5px; /* Adjust padding as needed */
}

.genre-card .card-title {
  margin: 0; /* Remove default margin */
}

.genre-name {
  text-align: center;
  font-family: $info-font;
  font-weight: bolder;
  color: $secondary; /* Set text color to white */
  font-size: 3.5em; /* Adjust the size as needed */
  text-shadow: -1px -1px 1px #000, 1px -1px 1px #000, 1px 1px 1px #000, -1px 1px 1px #000;
  @include respond-to('lg-desktop') {
    font-size: 4.5rem;
  }
}

