// Styling for individual program pages
.program-container {
  display: grid;
  grid-template-columns: 1fr;
  img {
    width: 100vw;
    // height: 100%;
  }
  .long-title {
    top: 35%;
  }
  .program-title {
    top: 45%;
  }
}

.program-hero {
  position: relative;
}

.cover {
  width: 100%;
}

.program-details {
  li {
    color: $primary;
    background-color: $secondary;
    // border-radius: $card-radius;
    padding: .5rem;
    &:first-child {
      border-top-left-radius: $card-radius;
      border-top-right-radius: $card-radius;
    }
    &:last-child {
      border-bottom-left-radius: $card-radius;
      border-bottom-right-radius: $card-radius;
    }
  }  
}

.program-tombstone-metadata { 
  font-family: $body-font;
  padding: .25rem;
  display: grid;
  grid-template-columns: 1fr;
  section {
    padding: 0 .5rem;
    text-align: center;
  }
  a {
    color: $links;
    font-weight: bolder;
    text-decoration: none;
    &:hover {
      color: $info;
      text-decoration: underline;
    }
  }
  li {
    list-style: none;
  }
  .metadata-key {
    font-weight: 800; 
    padding-top: 1rem;
  }
  .metadata-field {
    padding-left: 1rem;
  }
}

.program-description {
  // DESCRIPTION THAT APPEARS BELOW COVER IMAGE ON DESKTOP+
  padding: 1rem;
  font-family: $body-font;
  font-size: larger;
  color: $body;
}

// CONTAINER FOR THE PROGRAMMETADATA COMPONENT
.program-metadata-container {
  display: none;
  @include respond-to('desktop') {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 1rem;
    font-family: $body-font;
    font-size: larger;
    color: $body;
  }
}

.program-video-row {
  background-color: $info;
  width: 100%; 
  display: flex;
  padding: .5rem 0;
  position: relative;
  margin: auto;
  justify-content: space-around;
}

// DIV WHERE PROGRAM VIEWING BUTTONS LIVE ON MOBILE
.program-viewing {
  display: grid;
  grid-template-columns: 1fr;
  display: block;
  margin: auto;
  button, a {
    margin-top: .5rem;
    width: 80%;
  }
}

.program-card {
  position: relative;
  transition: all 0.3s ease;
  .card {
    padding: 0;
  }
}

.program-card .card-img-top {
  width: 100%;
  height: auto; /* Adjust height as needed */
}

.program-card .card-body {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 5px; /* Adjust padding as needed */
}

.program-card .card-title {
  margin: 0; /* Remove default margin */
}

// RESPONSIVE DESIGN
.mobile-tombstone {
  visibility: visible;
  margin-top: 2rem;
  @include respond-to('desktop') {
    display: none;
  }
  @include respond-to('lg-desktop') {
    display: none;
  }
}

.mobile-tombstone-metadata {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: large;
  .metadata-key, .metadata-field {
    padding: .5rem;
    display: block;
    color: $primary;
    background-color: $body;
    border-radius: $card-radius;
    color: $primary;
    text-align: center;
    margin: .5rem;
  } 
  .metadata-key {
    width: 32.5vw;
  } 
  .metadata-field {
    width: 52.5vw;
  }
}

// SHOWS THE PROGRAM TITLE ON A MOBILE SCREEN
.mobile-title {
  visibility: visible;
  padding: 0 .5rem 2rem .5rem;
  width: 70%;
  display: block;
  margin: auto;
  @include respond-to('desktop') {
    display: none;
  }  
}

.program-card .card-img-wrapper {
  position: relative;
  display: block;
}

.program-card .card-img-wrapper .program-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  color: white; /* Text color */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 20px; /* Adjust based on your design */
}

.program-card .card-img-wrapper:hover .program-card-overlay {
  opacity: 1;
}

.program-card-overlay {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  font-family: $info-font;
}

.program-press {
  a{
    color: $links;
    text-decoration: underline;
  }
}

.program-card {
  position: relative;
  transition: all 0.3s ease;
}

.title-and-ep-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ep-count {
  font-weight: bold; 
  visibility: hidden;
  margin-left: 1rem;
}

.program-card:hover .ep-count {
  display: block;
}

/* Additional styles to ensure the card title and ep-count visibility on hover */
.program-card:hover .title-container,
.program-card:hover .fw-bold,
.program-card:hover .ep-count,
.program-card:hover .program-card-overlay {
  visibility: visible;
}

.promo-container {
  margin-top: 10rem;
  color: $body;
  @include respond-to('lg-desktop') {
    margin-top: 15rem;
  }
}

.promo-video-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: $body;
  color: $primary;
  padding: 1rem;
  border-radius: $card-radius;
  gap: 10px;
  a {
    color: $info;
    &:hover {
      color: $links;
    }
  }
  img {
    display: block;
    margin: auto;
    width: 70%;
  }
  .btn-viewing {
    display: block;
    margin: auto;
  }
}

.promo-download-desc {
  padding: 2rem;
}


.promo-video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the grid items horizontally */
  gap: 20px; /* Adjust based on your spacing needs */
}
// * {
//   border: solid 1px yellow;
// }



