.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 999; /* Ensure it appears above other content */
  display: none; /* Initially hidden */
}

.modal-content, .modal-body {
  background-color: $secondary;
  border-radius: $card-radius;
}

.modal-header {
  background-color: $secondary;
  font-weight: 800;
  border: none;
}

.modal-video {
  height: auto;
  max-height: 70vh;
  max-width: 80vw;
  width: 100%;
}

.custom-modal-size .modal-dialog {
  max-width: 85vw;
  height: auto;
  max-height: 75vh;
}

.closeModal {
  max-width: 10%;
  margin: .25rem 1rem;
  border: none;
  background-color: $secondary;
}

.close-modal-wrapper {
  background-color: $secondary;
}

#emailModal {
  .modal-footer {
    display: block;
  }
}

.download-modal {
  font-family: $info-font;
  .modal-content {
    background-color: $body;
  }
  a {
    color: $info;
    &:hover {
      color: $links;
    }
  }
  .modal-footer {
    display: block;
  }
}

// MAKES DOWNLOAD MODAL SCROLLABLE AND DISPLAYS SCROLL BAR
.download-modal .modal-body {
  overflow-y: auto; /* Ensures vertical scroll */
  max-height: calc(100vh - 210px); /* Adjust the max height */
}
