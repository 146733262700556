// Form styles
.search-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.apt-inputs {
  margin: .25rem;
  width: 40vw;
}

.signin {
  display: block;
  margin: auto;
}

.form-control-lg, .form-control, .input-group-text, .btn-auth {
  border: .5px solid $body;
  border-radius: $card-radius;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: .5rem;
  margin-bottom: .5rem;
  @include respond-to('desktop') {
    width: 80%;
    margin-top: .75rem;
    margin-bottom: .75rem;
  }
}

.otp-instructions {
  span {
    width: 90%;
    @include respond-to('desktop') {
      width: 80%;
    }
  }
}

.auth-card {
  width: 95%;
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  background-color: $primary;
  box-shadow: 5px 5px 2px 1px #e6e6e6;
  border-radius: $card-radius;
  @include respond-to('tablet') {
    width: 80%;
  }
  @include respond-to('desktop') {
    width: 70%;
  }
  @include respond-to('lg-desktop') {
    width: 60%;
  }
}

// PROGRAM SUBMISSION FORM
.form-select {
  margin: .5rem 0;
}

.program-submission-container {
  background-color: $info;
  padding-bottom: 2rem;
  border-radius: $card-radius;
  margin-top: 12rem;
  label {
    font-weight: bolder;
    margin-left: 1.5rem;
    font-size: 1.25rem;
  }
  .row { margin: 0 1rem;}
}

.producer-input, .director-input {
  width: 100%;
  padding-left: 12px;
  .col-sm-6:last-child {
    padding-right: 0;
  }
}

// FOR THE ADD AWARD ROW ON PROGRAM SUBMISSIONS
.button-row {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 10px; /* Optional: Adds space between buttons */
}

.btn-add {
  flex-grow: 1; /* Allow buttons to grow and fill the container */
  max-width: 45vw; /* Maximum width of buttons */
}

/* If only one button exists in .button-row, it should have a width of 75vw */
.button-row:only-child .btn-add {
  max-width: 75vw;
}

/* Additional styling to ensure buttons take full width when both are present */
.remove-btn, .add-btn {
  width: calc(50% - 5px); /* Adjust based on gap */
}

/* Adjust this if only one button is visible to make it 75vw */
.button-row:only-child .btn-add {
  width: 75vw;
}

.prog-sub-toast {
  position: fixed; /* Stick to the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for exact centering */
  z-index: 1050; /* Ensure it's above most elements */
}

.prog-sub-link {
  visibility: hidden;
  @include respond-to('desktop') {
    visibility: visible;
  }
}

.distro-toggle-btns {
  width: 20vw;
  margin-left: 1rem;
  .btn {
    background-color: $body;
  }
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid #ccc; /* Optional: adds a border around the dropdown */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for depth */
}

.search-dropdown .list-group-item:hover {
  background-color: $body; /* Light grey background on hover */
  cursor: pointer;
}

.invalid-feedback {
  color: $body !important;
  font-family: $secondary-font;
  font-weight: bold;
  margin-left: 1rem;
  font-size: 1.25rem !important;
}

.input-container {
  position: relative;
  display: inline-block;
}

.input-container::after {
  content: "*";
  position: absolute;
  right: 20px; /* Adjust this value based on your needs */
  top: 5px; /* Adjust this value to align the asterisk vertically */
  color: $info; /* Optional: change the asterisk color */
}