button {
  border-radius: $card-radius;
}

.btn {
  margin: 1rem;
  width: 50vw;
  font-family: $info-font;
}

.btn-auth {
  padding: .5rem 1rem;
  margin-bottom: 2rem;
  border-radius: $card-radius;
  background-color: $secondary;
  color: $primary;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 800;
}

.btn-auth {
  transition: background-color 0.3s, border-color 0.3s;
}

.btn-gradient {
  background: linear-gradient(90deg, $primary 0%, $primary 100%);
  color: $body; /* Ensure text is readable on the gradient */
}


.btn-req {
  padding: .5rem 1rem;
  margin-bottom: 2rem;
  border-radius: $card-radius;
  background-color: $info;
  color: $primary;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  border: solid 2px white;
  display: block;
  margin: auto;
  a {
    text-decoration: none !important;
  }
}

.btn-program-details {
  color: $links;
  background-color: $secondary;
}

// FAB STUFF
.btn-fab {
  background-color: transparent;
  border: none;
  color: $secondary;
  display: block;
  margin: auto;
}

.btn-spinner {
  background-color: $links;
}

.btn-viewing, .btn-download{
  width: 20vw;
  margin: .5rem;
  padding: .25rem 0;
  background-color: $primary;
  color: $body;
  border: solid 1px $body;
  border-radius: $card-radius;
  text-align: center;
  text-transform: uppercase;
  padding-left: 0;
  display: block;
  font-weight: bolder;
}

.btn-email-submit {
  width: 80%;
  display: block;
  margin: auto;
  padding: .25rem 0;
  background-color: $links;
  color: $body;
  border: solid 1px $body;
  border-radius: $card-radius;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
}

.btn-email-reset, .btn-program-submission {
  width: 80%;
  display: block;
  margin: auto;
  padding: .25rem 0;
  background-color: $body;
  color: $links;
  border: solid 1px $links;
  border-radius: $card-radius;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
}

.btn-dropdown {
  text-align: center;
  .btn {
    font-size: 2rem;
    background-color: $secondary;
    color: $primary;
  }
}

.btn-close {
  color: $body;
  margin: 0;
}

.btn-logout, .btn-genre-dropdown {
  display: block;
  margin: auto;
  border-radius: $card-radius;
  text-transform: uppercase;
  font-weight: bold;
}

.btn-genre-dropdown {
  color: $primary;
  background-color: $secondary;
  border: double 3px $primary;
  border-radius: $card-radius;
  border: double 1px $primary;
}

.btn-logout {
  visibility: hidden;
  @include respond-to('desktop') {
    visibility: visible;
    background-color: $links;
    color: $secondary;
    border: double 1px $secondary;
  } 
}

 .btn-add {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 40vw;
 }