// Hero carousel
.hero-carousel {
  position: relative;
  img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
  & > .long-read {
    visibility: hidden;
  }
}

.genre-slider {
  background-color: transparent;
  .card-body {
    padding: 0;
  }
}

.genre-list {
  list-style: none;
  li {
    margin: .25rem;
  }
}

.slick-slide {
  transform: scale(0.8);
  // filter: blur(2px);
  position: relative;
  z-index: 1; /* Lower z-index for non-active slides */
}

.slick-center {
  transform: scale(1);
  filter: blur(0);
}

.slick-center {
  z-index: 2; /* Higher z-index for the active slide */
  /* Add more styles here to highlight the active slide */
}

/* Custom styling for slick-carousel arrows */
.slick-prev,
.slick-next {
  color: $body;
  padding: 2rem;
  z-index: 1;
}

.slick-arrow.slick-prev {
  left: 10px;
}

.slick-arrow.slick-next {
  right: 10px;
}

/* Custom styling for slick-carousel dots */
.slick-dots li button:before {
  margin: 1rem 0;
}


.program-carousel .carousel-control-prev,
.genre-carousel .carousel-control-prev,
.hero-carousel .carousel-control-prev {
  z-index: 1;
  left: 10px; /* Adjust as necessary */
}

.program-carousel .carousel-control-next,
.genre-carousel .carousel-control-next,
.hero-carousel .carousel-control-next {
  z-index: 1;
  right: 10px; /* Adjust as necessary */
}

/* Adjust the size and position of the inner icon if necessary */
.program-carousel .carousel-control-prev-icon,
.program-carousel .carousel-control-next-icon, 
.genre-carousel .carousel-control-prev-icon,
.genre-carousel .carousel-control-next-icon, 
.hero-carousel .carousel-control-prev-icon,
.hero-carousel .carousel-control-next-icon {
  z-index: 1;
  width: 40px; /* Adjust the width of the inner icon */
  height: 40px; /* Adjust the height of the inner icon */
}

// STYLING CAROUSEL INDICATORS
.carousel-indicators [data-bs-target] {
  border: 1px solid #000 !important;
  border-radius: $card-radius;
  margin: 10px 3px; 
}

@media screen and (orientation: landscape) and (max-height: 500px) {
  .hero-carousel, .program-carousel {
    height: 75vh;
    margin-bottom: 1rem;
  }
}