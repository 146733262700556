header {
  height: 40vh;
  @include respond-to('tablet') {
    height: 50vh;
  }
  @include respond-to('desktop') {
    height: 85vh;
  }
  @include respond-to('lg-desktop') {
    height: 85vh;
  }
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  img {
    padding-top: 5vh;
    width: 100%; /* Ensure the image covers the entire header */
    height: 100%; /* Ensure the image covers the entire header */
    object-fit: cover; /* Optional, depending on your design */
  }
}

.hero-container {
  position: relative;
  text-align: center;
}

// PUSHES IMAGE BELOW GRADIENT
.hero-img {
  padding-top: 50px;
}

.hero-img-wrapper {
  position: relative;
}

.cover {
  width: 100%;
  /* Adjust height as needed */
  height: auto;
}

.hero-text {
  visibility: hidden;
  @include respond-to('desktop') {
    position: absolute;
    visibility: visible;
    top: 50%; /* Center vertically */
    left: 70%; /* Center horizontally */
    transform: translate(-50%, -50%);
    color: white; /* Adjust text color as needed */
    text-align: center;
    background-color: $info;
    padding: 1rem;
    opacity: .8;
    border-radius: $card-radius;
  }
  @include respond-to('lg-desktop') {
    position: absolute;
    visibility: visible;
    top: 50%; /* Center vertically */
    left: 70%; /* Center horizontally */
    transform: translate(-50%, -50%);
    color: white; /* Adjust text color as needed */
    text-align: center;
    background-color: $info;
    padding: 1rem;
    opacity: .8;
    border-radius: $card-radius;
  }
}

/* Additional styling for the text if needed */
.hero-text h4 {
  margin: 0;
  padding: 0;
  font-family: $secondary-font;
  /* other styles */
}

.long-title, .program-title {
  visibility: hidden; // Default state for mobile, assuming "respond-to" is a mixin for media queries
  text-shadow: -1px -1px 1px #000, 1px -1px 1px #000, 1px 1px 1px #000, -1px 1px 1px #000;
  z-index: 5; // Ensure it's above other elements
  position: absolute; // Positioned absolutely within a relative parent
  font-family: $info-font;
  font-weight: bolder;
  color: $secondary;
  text-align: center; // Align text center, adjustments made to ensure it's truly centered below
  @include respond-to('desktop') {
    visibility: visible;
    top: 40%; // Center vertically in the middle
    left: 50%; // Center horizontally
    transform: translate(-50%, -50%); // Adjust translation to truly center the element
    font-size: 2.5em;
  }

  @include respond-to('lg-desktop') {
    font-size: 3.5rem; // Larger text for larger desktops
  }
}

.tag-line {
  color: $body;
  font-size: .75rem;
}

.long-title, .program-title {
	top: 60vh;
	transform: translate(-50%, 0%);
}