footer {
  margin-top: auto;
  font-family: $secondary-font;
  background-color: $secondary;
  font-size: .5rem;
  line-height: .5;
  position: relative;
  @include respond-to('tablet') {
    line-height: .75;
    font-size: .75rem;
  }
  @include respond-to('desktop') {
    font-size: 1rem;
  }
  @include respond-to('lg-desktop') {
    font-size: 1.25rem;
  }
  a {
    color: $primary;
    text-decoration: none;
    &:hover {
      color: $links;
      text-decoration: underline;
    }
  }
}

// USED TO ENSURE THE FOOTER IS SENT TO THE BOTTOM OF THE PAGE
.root-layout {
  display: flex;
  background-color: $primary;
  flex-direction: column;
  min-height: 100vh;
}

footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(180deg, #F2F2F2, transparent);
  z-index: 1;
}

.social-media-row, .link-row {
  margin-bottom:  1rem;
  width: 100%; 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .5rem 0;
  div {
    margin: auto;
    justify-content: space-around;
  }
}

.social-media-icon, .link-row-item {
  margin: .5rem;
  padding: .25rem 0;
  background-color: transparent;
  display: block;
}

// PROVIDES A HIDDEN VERSION NUMBER FOR CHECKING ON AWS
.hide-version {
  visibility: hidden;
}

.bottom-right-social {
  display: flex;
  direction: rtl;
}