html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  // background-color: $body;
  font-family: $body-font;
}

.blurb-container, .long-read-container, .program-submission-container {
  position: relative;
  padding: .25rem;
  @include respond-to('desktop') {
    // margin-top: 50px;
    padding-top: 2rem;
    font-size: large;
    line-height: 2;
  }
  @include respond-to('lg-desktop') {
    padding-top: 2.5rem;
    font-size: larger;
  }
  a {
    color: $body;
    &:hover {
      color: $links;
    }
  }
}

.qr-header {
  margin-top: 20vh;
}

// FOR ACCESSIBILITY
.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: white;
  padding: 8px;
  z-index: 100;

  &:focus {
    top: 0;
  }
}

.contact-list {
  list-style: none;
}

.qr-container {
  display: grid;
  margin: auto;
  text-align: center;
  @include respond-to('desktop'){
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: .25rem;
    margin: auto;
    text-align: center;
    font-weight: bold;
  }
  img {
    margin-bottom: 1rem;
  }
  a {
    color: $body;
    &:hover {
      color: $links;
    }
  }
}

