.card {
  border: none;
  a {
    color: $primary;
    text-decoration: none;
  }
  a:hover {
    color: $links;
    text-decoration: underline;
    border: solid 1px $links;
  }
}

.card-title {
  text-align: center;
  padding: .5rem 0;
}

.card-text {
  padding: 0 .25rem;
  @include respond-to('desktop') {
    padding: 0 .5rem;
  }
}