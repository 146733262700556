// MIXINS
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Breakpoints map
$breakpoints: (
  'tablet': (min-width: 768px),
  'desktop': (min-width: 1023px),
  'lg-desktop': (min-width: 1408px),
) !default;

// COLORS
$primary: #091A36;
$secondary: #F2F2F2;
$body: #F2F2F2;
$info: #037F8C;
$links: #1AC6D9;

main {
  color: $body;
  padding-bottom: 1rem;

  &.subheader-background {
    background-color: $info;
  }
  &.standard {
    background-color: $primary;
  }
}

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital@0;1&family=Nunito:wght@800&family=Roboto&display=swap');

$secondary-font: 'Nunito', sans-serif;
$info-font: 'Roboto', sans-serif;
$body-font: 'Barlow', sans-serif; 

h1, h2, h3 {
  font-family: $secondary-font;
}
// SWITCH CAROUSEL CAPTION AND FEATURED PROGRAMS HE
h4, h5, h6 {
  font-family: $info-font;
}

body {
  font-family: $body-font;
  color: $body;
}

// BORDER RADIUS
$card-radius: .5rem;