// For the popup video modal on the GenreCarousel
.popover {
  display: grid;
  height: 100%;
  min-width: 100%;
  border: none;
}

.popover-arrow {
  visibility: hidden;
}

.title-row {
  display: flex;
  margin:auto;
  padding: .5rem 0 0 0;
  text-align: center;
  a {
    margin: 0;
    padding: 0;
  }
}

.video-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.program-popover {
  background: transparent;
  visibility: hidden;
  video {
    width: 45vw;
    margin: auto;
    display: block;
    &:hover {
      .program-description {
        display: block;
      }
    }
  }
}

.genre-item{
  &:hover {
    .program-popover {
      visibility: visible;
    }
  }
  .card {
    background: transparent;
  }
}