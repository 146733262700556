.gradient-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent; 
  z-index: 10; 
  align-items: center;
  background: rgb(9,26,54);
  background: linear-gradient(180deg, rgba(9,26,54,1) 55%, rgba(0,212,255,0) 100%);
  a {
    color: $body;
    font-family: $info-font;
    font-weight: bold;
    font-size: 1.25rem;
  }
  .nav-link {
    color: $body;
    padding: .25rem;
  }
}

.navbar-brand {
  width: 20vw;
  @include respond-to('desktop') {
    width: 10vw;
  }
  @include respond-to('lg-desktop') {
    width: 8vw;
  }
}

// Offcanvas overlay used for responsive styling
.offcanvas.show {
  background-color: $primary;
  max-width: 90%;
  a {
    color: $secondary;
    &:hover{
      color: $links;
    }
  }
  .navbar-nav .nav-link {
    text-align: center;
    margin: 1rem 0;
    font-size: 1rem;
    @include respond-to('desktop') {
      font-size: 1.75rem;
    }
  }
  .nav-item {
    font-size: 2rem;
    padding: .5rem;
    a {
      text-decoration: none;
    }
    @include respond-to('desktop') {
      text-align: center;
      font-size: 2rem;
    }
  }
  span {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1rem;
    @include respond-to('desktop') {
      font-size: 1.75rem;
    }
  }
  .dropdown-menu.show {
    left: 2rem;
    a {
      color: $primary;
    }
  }
}

// ONLY SHOWS WHEN ON DESKTOP OR LG-DESKTOP
.desktop-nav {
  display: none;
  .nav-item {
    font-family: $info-font;
    display: flex;
    align-items: center;
    padding: 0 3rem;
    font-weight: 600;
    // font-size: 1.5rem;
    a {
      color: $secondary;
    }
  }
  @include respond-to('desktop') {
    display: inline-flex;
  }
  @include respond-to('lg-desktop') {
    display: inline-flex;
  }
}

.mobile-search {
  // max-width: 95%;
  margin: 0 auto;
  display: block;
  @include respond-to('desktop') {
    display: none;
  }
}

.mobile-dropdown {
  display: block;
  margin: auto;
  input {
    padding: .5rem;
  }
  .btn {
    background-color: $secondary;
    border: solid 1px $links;
    color: $body;
    :focus {
      background-color: $links;
    }
  }
  .dropdown-toggle-split {
    background-color: $secondary;
  }
  .dropdown-toggle-split.show {
    background-color: $links;
  }
}

.dropdown-toggle {
  a {
    color: $primary;
    text-decoration: none;
  } 
}

.user-info {
  border-bottom: solid 2px $links;
}

/* Align the dropdown menu to the left of the NavDropdown toggle */
.genre-dropdown .dropdown-menu {
  right: 0; /* Align the right edge of the dropdown menu with the right edge of the toggle */
  left: auto; /* Reset any specific left alignment */
}

.genre-dropdown {
  .nav-item {
    font-size: 1.25rem;
    a {
      color: $primary;
      text-decoration: none;
      &:hover, &:focus {
        color: $links;
      }
    }
  }
}

.nav-search-form {
  .input-group-text {
    max-width: 15%;
  }
}

// WHITE PLACEHOLDER AND INPUT TEXT IN NAVBAR ONLY
.ww-navbar {
  .nav-search-form input {
    /* To change the placeholder text color to white as well */
    ::placeholder {
      color: $secondary;
      opacity: 1; /* For full opacity; adjust as needed */
    }
  
    /* If you want to ensure the text stays white even when the input is focused */
    &:focus {
      color: $secondary;
      background-color: transparent; /* Reiterate background color just in case */
    }
    background-color: transparent;
    border: solid 1px $body;
    color: $secondary; /* This sets the text color to white */
    font-weight: 600;
  }
}

// ONLY DISPLAY SEARCHINPUT ON DESTOP IN THE NAVBAR, OTHERWISE IT IS IN THE OFFCANVAS
.desktop-search {
  display: none;
  @include respond-to('desktop') {
    display: block;
    margin-right: 2rem;
  }
}

.navbar-toggler {
  display: block;
}

.ww-navbar {
  width: 100%; /* Ensure Navbar spans the full width */
}

.ww-navbar-toggler {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-bottom: 1rem;
}

.ww-navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important; /* White lines for the hamburger icon */
  // border: solid 2px $body;
  margin-bottom: 1rem;
}

.ww-offcanvas-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .5rem;
}
/* Style for the close button in the offcanvas */
.ww-offcanvas-header .btn-close {
  color: $body; /* Make the close icon white */
  filter: invert(1) grayscale(100%) brightness(200%); /* Alternative method to ensure the icon is white */
}

/* Style for the user info in the offcanvas */
.ww-offcanvas-header .user-info {
  color: $body; /* Text color for the user info */
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.genre-tooltip-container {
  position: relative; /* This makes the absolute positioning of the dropdown relative to this container */
}

/* Dropdown styling */
.genre-tooltip {
  position: absolute; /* Position it absolutely to place it over other content */
  z-index: 2000; /* High z-index to ensure it's in front of other elements */
  /* Your other styling for the dropdown */
}

.genre-tooltip-trigger {
  color: $body;
}

.genre-tooltip {
  position: absolute;
  top: 100%; /* Align the top of the tooltip with the bottom of the button */
  left: 50%; /* Start from the middle of the button */
  transform: translateX(-50%); /* Center the tooltip horizontally relative to the button */
  margin-top: 10px; /* Add some space between the tooltip and the button */
  background-color: $secondary;
  border: 1px solid $links;
  border-radius: 5px;
  padding: 10px;
  z-index: 1051;
  /* Add other styling as needed */
  a {
    color: $primary;
    text-decoration: none;
    &:hover {
      color: $links;
      text-decoration: underline;
    }
  }
}

// USER LOGIN AND LOGOUT BTNS ARE THE SAME SIZE AS GENRE DROPDOWN BTN
.user-nav {
  display: none;
  @include respond-to('desktop') {
    display: block;
    a {
      font-size: 1rem;
    }

  }
}

.user-email {
  font-family: $info-font;
  color: $body;
}

// PAGES PROPERLY SIT BELOW TRANSPARENT NAVIGATION
.auth-container {
  margin-top: 10rem;
  padding: 2rem 0;
  background-color: $info;
  border-radius: $card-radius;
  @include respond-to('desktop') {
    padding: 2rem 0;
  }
  @include respond-to('lg-desktop') {
    padding: 3rem 0;
    margin-top: 15rem;
  }
}

.mobile-user {
  visibility: visible;
  display: block;
  margin: auto;
  width: 75%;
  @include respond-to('desktop') {
    visibility: hidden;
  }
  a {
    color: $body;
    border: solid 1px $links;
    border-radius: $card-radius;
    padding: .5rem;
    text-decoration: none;
    &:hover {
      color: $links;
      text-decoration: underline;
    }
  }
}

.error-nav {
  background-color: $primary;
}

.error-search {
  display: block;
  margin: auto;
  width: 90%;
  .nav-search-form {
    margin-right: 0;
  }
}

.error-layout {
  display: block;
  margin: auto;
  font-family: $info-font;
  a {
    font-size: 1.25rem;
    text-align: center;
    color: $body;
    &:hover {
      color: $links;
    }
  }
}

