.my-spinner {
  padding: 10rem 0;
  text-align: center;
  .btn:disabled {
    background: $links;
  }
}

.my-toasts {
  position: relative;
  padding: 3rem 0;
  text-align: center;
  p {
    font-size: 1.5rem;
    margin: 0;
    padding: .5rem;
  }
}

.my-toasts.hide {
  display: none;
}

.modal-top {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.download-spinner {
  padding: 1rem 0;
}
