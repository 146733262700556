@charset "UTF-8";

@import
// ENSURE VARIABLES IS DEFINED FIRST SO IT CAN BE USED BY ALL OTHER STYLES
  'layouts/variables',
  'layouts/general',
  'layouts/cards',
  'layouts/hero',
  'layouts/utilities',
  'layouts/nav',
  'layouts/footer',
  'layouts/buttons';

@import
  'components/carousel',
  'components/modal',
  'components/error',
  'components/forms',
  'components/popovers';


@import 
  'facets/genres',
  'facets/programs';

// Allows for the use of custom color variables
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';


